.rsm-btm-button {
  margin-top: $spacer * 4;
  margin-bottom: $spacer * 6;
  text-align: center;

  .btn {
    padding: 0.7rem 1.7rem;
    border-radius: 0;
  }
}

.main-collection-search-form,
.sacred-collection-search-form {
  margin: 0px;

  &__field {
    margin-bottom: $spacer;
    width: 100%;
  }

  @include media-breakpoint-up(md) {
    gap: 1.5rem;
    display: grid;
    grid-template-columns: auto auto auto;

    &__field {
      &__submit {
        margin-top: 2rem;
      }
    }
  }
}
