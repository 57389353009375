.rsm-detail {
  @include rsm-container;
  margin: auto;
  padding-top: $spacer * 4;
  padding-bottom: $spacer * 2.5;
  position: relative;

  .rsm-detail__paragraph {
    max-width: 750px;
    margin: auto;
  }

  &.rsm-detail-inner {
    padding-bottom: 10rem;

    .rsm-detail__paragraph {
      margin-left: auto;
      margin-right: auto;
    }

    @include media-breakpoint-down(md) {
      padding-bottom: 3rem;
      .rsm-detail__paragraph {
        .back-link {
          margin-top: 7rem;
        }
      }
    }
  }

  &__artifact {
    display: grid;
    grid-template-columns: 458px 1fr;
    gap: 30px;
    margin-bottom: 2rem;

    @include media-breakpoint-down(lg) {
      grid-template-columns: 380px 1fr;
    }

    @include media-breakpoint-down(md) {
      display: block;
    }
  }

  &__instruction {
    padding-top: 2.5rem;
    h2 {
      @include line-on-top;
      @extend .h3;
      text-transform: uppercase;
      padding-top: 9px;
      margin-bottom: 1.25rem;

      &:before {
        width: 41px;
      }
    }

    p {
      line-height: 1.625em;
      margin-bottom: 10px;
      span {
        color: $oh-teal;
        font-weight: 600;
      }
    }
  }

  &__instruction--top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__info-button {
    @extend .btn;
    @extend .btn-primary;

    position: relative;
    border-radius: 9999px;
    width: 30px;
    height: 30px;

    * {
      color: $black;
    }

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__paragraph {
    &__collapse {
      position: relative;
      min-height: 80px !important;
      display: block !important;

      > * {
        &:last-child {
          margin-bottom: 0;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0;
        height: 80px;
        background-image: linear-gradient(
          to top,
          $oh-black,
          rgba($oh-black, 0)
        );
        transition: opacity 0.3s ease-out;
      }

      &.collapse {
        &:not(.show) {
          max-height: 80px;
          overflow: hidden;

          &:after {
            opacity: 1;
          }
        }

        &.show {
          &:after {
            opacity: 0;
          }
        }
      }
    }

    .collapse-button {
      display: block;
      margin: 20px 0px 30px;
      position: relative;
      min-width: 120px;
      text-align: center;

      &:focus {
        &:not(&:hover) {
          box-shadow: 0 0 15px 0 rgba($oh-teal, 0.4);
        }
      }
    }

    p {
      line-height: 1.625em;
    }

    .back-link {
      @include oh-link-glow;
      display: block;
      text-decoration: none;
      margin-top: 1.5rem;

      &:before {
        font: var(--fa-font-regular);
        margin-right: 1rem;
        content: '\f053';
        font-weight: 900;
      }
    }
  }
}

.rsm-detail + .rsm-detail {
  padding-top: 0px;
}

.collection-child {
  border-bottom: 1px solid $oh-border;

  .rsm-detail__artifact,
  .rsm-detail__paragraph {
    max-width: 750px;
    margin: auto;
  }

  .rsm-detail__paragraph {
    h3 {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.625em;
    }
  }
}
