// Typography
//
// Font, line-height, and color for body text, headings, and more.

// scss-docs-start font-variables
// stylelint-disable value-keyword-case
$font-family-sans-serif: 'fira-sans', sans-serif;
//$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
// stylelint-enable value-keyword-case
//$font-family-base:            var(--#{$prefix}font-sans-serif);
//$font-family-code:            var(--#{$prefix}font-monospace);

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
//$font-size-root:              null;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
//$font-size-sm:                $font-size-base * .875;
//$font-size-lg:                $font-size-base * 1.25;

//$font-weight-lighter:         lighter;
//$font-weight-light:           300;
//$font-weight-normal:          400;
//$font-weight-semibold:        600;
//$font-weight-bold:            700;
//$font-weight-bolder:          bolder;

// $font-weight-base: 500;

//$line-height-base:            1.5;
//$line-height-sm:              1.25;
//$line-height-lg:              2;

$h1-font-size: $font-size-base * 3;
$h2-font-size: $font-size-base * 2.5;
$h3-font-size: $font-size-base * 1.722;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.111;
$h6-font-size: $font-size-base;
// scss-docs-end font-variables

// scss-docs-start headings-variables
//$headings-margin-bottom:      $spacer * .5;
//$headings-font-family:        null;
//$headings-font-style:         null;
$headings-font-weight: 700;
//$headings-line-height:        1.2;
//$headings-color:              null;
// scss-docs-end headings-variables

// scss-docs-start display-headings
/*$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem
);*/

//$display-font-family: null;
//$display-font-style:  null;
//$display-font-weight: 300;
//$display-line-height: $headings-line-height;
// scss-docs-end display-headings

// scss-docs-start type-variables
//$lead-font-size:              $font-size-base * 1.25;
//$lead-font-weight:            300;

//$small-font-size:             .875em;

//$sub-sup-font-size:           .75em;

//$text-muted:                  $gray-600;

//$initialism-font-size:        $small-font-size;

//$blockquote-margin-y:         $spacer;
//$blockquote-font-size:        $font-size-base * 1.25;
//$blockquote-footer-color:     $gray-600;
//$blockquote-footer-font-size: $small-font-size;

//$hr-margin-y:                 $spacer;
//$hr-color:                    inherit;

// fusv-disable
//$hr-bg-color:                 null; // Deprecated in v5.2.0
//$hr-height:                   null; // Deprecated in v5.2.0
// fusv-enable

//$hr-border-color:             null; // Allows for inherited colors
//$hr-border-width:             $border-width;
//$hr-opacity:                  .25;

//$legend-margin-bottom:        .5rem;
//$legend-font-size:            1.5rem;
//$legend-font-weight:          null;

//$dt-font-weight:              $font-weight-bold;

//$list-inline-padding:         .5rem;

//$mark-padding:                .1875em;
//$mark-bg:                     $yellow-100;
// scss-docs-end type-variables
