// General site setup - RSM Indigenous Collection - Stylesheet by OH Media
// Any 'body' and 'html' elements are being referenced and taken care of in the bootstrap files

:root {
  --rsm-container-padding: 25px;
}

// ability to reduce the padding for different breakpoints
@include media-breakpoint-up(sm) {
  :root {
    --rsm-container-padding: calc(50vw - 270px);
  }
}

@include media-breakpoint-up(md) {
  :root {
    --rsm-container-padding: calc(50vw - 360px);
  }
}

@include media-breakpoint-up(lg) {
  :root {
    --rsm-container-padding: calc(50vw - 460px);
  }
}

@include media-breakpoint-up(xl) {
  :root {
    --rsm-container-padding: calc(50vw - 550px);
  }
}

@include media-breakpoint-up(xxl) {
  :root {
    --rsm-container-padding: calc(50vw - 600px);
  }
}

@include media-breakpoint-up(xxxl) {
  :root {
    --rsm-container-padding: calc(50vw - 700px);
  }
}

html {
  --rsm-line-height: 1.1em;
  --rsm-display-line-height: 0.95em;
  --rsm-display-title-size: 7rem;
  --rsm-title-size: calc(1.425rem + 2.1vw);
  --rsm-link-width: min-content;
  --rsm-display-text-size: 5rem;
  --rsm-display-text-width: 100%;

  @include media-breakpoint-down(sm) {
    --rsm-display-title-size: 14vw;
  }
}

html:lang(en) {
  --rsm-line-height: 0.85em;
  --rsm-display-line-height: 0.76em;
  --rsm-display-title-size: 8.5rem;
  --rsm-title-size: calc(1.425rem + 2.1vw);

  @include media-breakpoint-down(sm) {
    --rsm-display-title-size: 14vw;
  }
}

html:lang(cr) {
  --rsm-line-height: 1.1em;
  --rsm-display-line-height: 1em;
  --rsm-display-title-size: 6rem;
  --rsm-title-size: calc(1.425rem + 2.1vw);

  @include media-breakpoint-down(sm) {
    --rsm-display-title-size: 11vw;
  }
}

html:lang(dn) {
  --rsm-line-height: 1.05em;
  --rsm-display-line-height: 1em;
  --rsm-display-title-size: 6rem;
  --rsm-title-size: 10vw;
  --rsm-link-width: 300px;
  --rsm-display-text-size: 3.5rem;
  --rsm-display-text-width: 22rem;

  @include media-breakpoint-down(sm) {
    --rsm-display-title-size: 14vw;
    --rsm-link-width: 100%;
    --rsm-display-text-size: 11vw;
    --rsm-display-text-width: 76vw;
  }
}

html:lang(mc) {
  --rsm-line-height: 1.05em;
  --rsm-display-line-height: 1em;
  --rsm-display-title-size: 4.75rem;
  --rsm-title-size: 6.5vw;

  @include media-breakpoint-down(sm) {
    --rsm-display-title-size: 12vw;
  }
}

@mixin rsm-container {
  padding-left: var(--rsm-container-padding);
  padding-right: var(--rsm-container-padding);
}

img {
  display: block;
  @include img-fluid;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    text-decoration: none;
  }
}

button {
  border: none;
  outline: none;
  background-color: transparent;
}

.btn.btn-primary {
  &:hover,
  &:focus {
    background-color: $primary;
    color: $black;
    box-shadow: 0 0 35px 0 rgba($oh-teal, 0.4);
  }

  &:active,
  &.active {
    background-color: $oh-light-teal;
  }
}

.rsm-detail-outer {
  background-color: $black;
  border-bottom: 1px solid $oh-border;
  position: relative;
  z-index: 1;
}

.breadcrumbs {
  ul {
    list-style-type: none;
    padding-left: 0px;
    display: flex;
    margin-bottom: 10px;
    flex-wrap: wrap;

    li {
      font-weight: 300;
      font-size: 0.88rem;
      color: rgba($oh-teal, 0.4);

      .divider {
        margin: 0 8px 0px 6px;
        font: var(--fa-font-regular);
        &::after {
          content: '\f054';
          font-weight: 900;
          font-size: 0.63rem;
          color: rgba($white, 0.15);
        }
      }

      a {
        text-decoration: underline;
        @include oh-link-glow;
      }
    }
  }
}

#wrap {
  overflow: hidden;
}

//fancybox carousel style
#main-item-carousel {
  --f-carousel-spacing: 10px;
  --f-carousel-slide-width: 100%;

  .f-carousel__slide {
    padding: 0px;
  }

  .f-carousel__dots {
    display: none;
  }

  .f-button {
    background-color: $oh-teal;
    bottom: 5px;
    top: unset;
    border-radius: 50%;
    color: $black;
    width: 30px;
    height: 30px;
    transform: none;

    svg {
      stroke: $black;
      width: 18px;
      height: 22px;
      stroke-width: 3px;
    }

    &.is-next {
      right: 0;
    }

    &.is-prev {
      left: unset;
      right: 42px;
    }

    &[disabled] {
      background-color: #1f4c42;

      svg {
        stroke: $black;
      }
    }
  }

  .rsm-carousel-object {
    .rsm-carousel-plus {
      position: absolute;
      top: 14px;
      right: 14px;
      padding: 7px 12px;
      background-color: rgba($black, 30%);
      transition-duration: 0.3s;
      i {
        color: $oh-teal;
      }
    }

    &:hover {
      .rsm-carousel-plus {
        background-color: rgba($black, 60%);
      }
    }
  }

  .rsm-carousel-lower {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    &:last-child {
      margin-right: 80px;
    }

    p {
      margin-bottom: 0px;
      line-height: 40px;
      font-weight: 300;
    }
  }
}

.fancybox__caption {
  font-weight: 300;
}

.fancybox__text {
  --fancybox-content-bg: #{$black};
  --fancybox-content-color: #{$white};
}

blockquote {
  border-left: 5px solid #ccc;
  font-style: italic;
  margin-left: 0;
  margin-right: 0;
  overflow: hidden;
  padding-left: 1.5em;
  padding-right: 1.5em;

  p {
    margin: 0px;
  }
}

figure.table {
  all: unset;

  > table {
    @extend .table;
  }
}

.pagination {
  justify-content: center;
}

.rsm-detail__info-button {
  margin-top: 9px;
}

@import 'global/rsm-grid';
@import 'global/header';
@import 'global/footer';
