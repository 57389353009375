@import '~bootstrap/scss/functions';

@import 'variables';

/**
 * Useful mixins:
 *
 *   nodes_modules/bootstrap/scss/mixins/_border-radius.scss
 *   nodes_modules/bootstrap/scss/mixins/_breakpoints.scss
 *   clearfix()
 *   img-fluid()
 *   list-unstyled()
 *   reset-text()
 *   text-truncate()
 *   visually-hidden()
 *   visually-hidden-focusable()
 */
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/type';
//@import '~bootstrap/scss/offcanvas';

// https://getbootstrap.com/docs/5.3/layout/containers/
@import '~bootstrap/scss/containers';

// Only 1 of these grids should be enabled

// https://getbootstrap.com/docs/5.3/layout/grid/
$enable-grid-classes: true;

// They say their CSS Grid is experimental and opt-in
// https://getbootstrap.com/docs/5.3/layout/css-grid/
$enable-cssgrid: not $enable-grid-classes;

@import '~bootstrap/scss/forms';

@import '~bootstrap/scss/grid';

// https://getbootstrap.com/docs/5.3/components/buttons/
@import '~bootstrap/scss/buttons';

// https://getbootstrap.com/docs/5.3/components/close-button/
@import '~bootstrap/scss/close';

/**
 * Includes various classes for consistency:
 * https://getbootstrap.com/docs/5.2/utilities/spacing/
 *
 * This is so everyone's spacing can be based off of the $spacer variables
 * without having to actually work directly with those variables.
 *
 * These classes can be applied in HTML or extended in Sass
 */
@import 'bootstrap/utilities';

// For https://getbootstrap.com/docs/5.3/components/collapse/ functionality
@import '~bootstrap/scss/transitions';

@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/tables';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/pagination';
@import '~bootstrap/scss/navbar';

// https://getbootstrap.com/docs/5.3/components/navs-tabs/#variables
@import '~bootstrap/scss/nav';

// https://getbootstrap.com/docs/5.3/components/accordion/#variables
//@import '~bootstrap/scss/accordion';

// https://getbootstrap.com/docs/5.3/components/alerts/#variables
@import '~bootstrap/scss/alert';

@import 'https://use.fontawesome.com/releases/v6.4.2/css/all.css';

@import url('https://use.typekit.net/sma0tfu.css');

@import '~@fancyapps/ui/dist/fancybox/fancybox';
@import '~@fancyapps/ui/dist/carousel/carousel';

@import 'mixin';
@import 'global';
@import 'pages/homepage';
@import 'pages/collection';
@import 'pages/collection-child';
