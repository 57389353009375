// Footer Styles
footer {
  @include rsm-container;
  padding-top: 3.5rem;
  padding-bottom: 2.5rem;

  .footer-top {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &__nav {
      display: flex;
      ul {
        list-style-type: none;
        padding-left: 0px;
        display: flex;
        gap: 2.5rem;
        flex-wrap: wrap;
        margin: auto 0px;

        li {
          a {
            color: $white;
            font-weight: 500;
            transition: color ease-in-out 0.3s;
          }

          &:hover {
            a {
              color: $oh-teal;
            }
          }

          &:active,
          &.active,
          &:focus,
          &.focus {
            a {
              color: $oh-teal;
              text-shadow: 0px 0px 35px $oh-teal;
            }
          }
        }
      }
    }
    @include media-breakpoint-down(lg) {
      flex-direction: column;
      align-items: center;
      gap: 3rem;
      &__logo {
        margin-left: 0px;
      }
      &__nav {
        ul {
          align-items: center;
          gap: 1.5rem 3rem;
          justify-content: center;
        }
      }
    }
  }
  .footer-btm-logo {
    max-width: 310px;
    margin-top: 2rem;
    margin-left: auto;
    @include media-breakpoint-down(lg) {
      margin-top: 3rem;
      margin-right: auto;
    }
  }
}
