// Custom global variables
// NOTE: all custom variables should be prefixed with "oh-"
// to distinguish them from Bootstrap and avoid naming collisions
$oh-teal: #4af6cd;
$oh-light-teal: #81fce9;

$oh-black: #0f1010;
$oh-dark-gray: #171919;

$oh-white: #fff;

$oh-border: #2b2d2d;

$oh-horizontal-padding: 15rem;

// bootstrap variable overrides
@import 'bootstrap/variables';

// NOTE: HTML smooth scrolling messes with other functionality
// like anchor tags, form validation, tabbing through links, etc.
$enable-smooth-scroll: false;

@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
