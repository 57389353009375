body.homepage {
  header {
    .header-top__title a {
      display: none;
    }
  }

  main {
    padding-top: 0px;
  }

  .display-title {
    text-wrap: wrap;
    position: absolute;
    left: -3rem;
    top: 3.5rem;

    @include media-breakpoint-down(lg) {
      top: 5rem;
    }

    @include media-breakpoint-down(md) {
      left: 40%;
      top: 3.5rem;
      transform: translateX(-35vw);
    }

    @include media-breakpoint-down(sm) {
      left: 10px;
      transform: translateX(0%);
    }
  }

  .page-main-title {
    aspect-ratio: 2.16;
    max-width: 100%;
    background-image: url('/assets/frontend/images/homepage-banner.jpg');
    box-shadow: inset 0px 0px 136px 136px $oh-dark-gray;
    background-repeat: no-repeat;
    background-position: 90% 40%;
    position: relative;
    min-height: 450px;

    .page-title {
      padding-top: 24px;

      @include media-breakpoint-down(sm) {
        font-size: var(--rsm-title-size);
      }

      &:before {
        width: 80px;

        @include media-breakpoint-down(sm) {
          width: 34px;
        }
      }
    }

    @include media-breakpoint-down(lg) {
      padding-top: 1.5rem;
    }

    @include media-breakpoint-down(md) {
      @include full-md-screen;
      padding-top: 1.5rem;
      box-shadow: none;
      width: 100vw;
      max-width: 100vw;
    }
  }

  .rsm-grid {
    .rsm-grid-item {
      padding-top: $spacer * 5;
      padding-bottom: $spacer * 5;

      &__title {
        width: var(--rsm-link-width);
        margin-bottom: $spacer * 2;
        text-decoration: none;

        .display-text {
          width: var(--rsm-display-text-width);
        }
      }
    }

    a {
      text-decoration: none;

      p {
        color: $oh-teal;
      }
    }
  }
}

.homepage-paragraph-block {
  @include rsm-container;
  padding-top: $spacer * 6;
  padding-bottom: $spacer * 6;
  display: flex;
  border-bottom: 1px solid $oh-border;

  p {
    max-width: 750px;
    margin: auto;
  }
}
