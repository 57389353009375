//RSM Sections --- This is the style for both sections in collection page and homepage
:root {
  --rsm-grid-item-padding: 25px;
}

// ability to reduce the padding for different breakpoints
@include media-breakpoint-up(xl) {
  :root {
    --rsm-grid-item-padding: 50px;
  }
}

// add breakpoints here as necessary to adjust the value of --rsm-grid-item-padding

@mixin rsm-grid-line-vertical() {
  content: '';
  position: absolute;
  height: calc(500rem + 100% + 500vh);
  width: 1px;
  background-color: $oh-border;
  right: -1px;
  bottom: 0%;
  pointer-events: none;
}

.rsm-grid {
  display: grid;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 1px;
    background-color: $oh-border;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    bottom: 0;
  }

  .rsm-grid-item {
    position: relative;
    display: block;
    text-decoration: none;
    border-bottom: 1px solid $oh-border;
    padding-left: var(--rsm-grid-item-padding);
    padding-right: var(--rsm-grid-item-padding);
    padding-top: 70px;
    padding-bottom: 56px;

    .rsm-grid-item__view {
      @include oh-link-glow;
      color: $oh-teal;
    }

    &:hover,
    &:focus,
    &:active,
    &.active {
      background-color: $black;

      .rsm-grid-item__view {
        text-shadow: 0 0 24px $oh-teal;
      }
    }

    .rsm-grid-item__title {
      color: $body-color;
      text-transform: uppercase;
      line-height: var(--rsm-line-height);
      margin-bottom: 0.5rem;
      padding-top: 1rem;
      @include line-on-top;

      span {
        position: relative;
      }

      .display-text {
        position: absolute;
        top: 50%;
        left: -1.5rem;
      }

      &:before {
        width: 40px;
      }
    }

    &.rsm-grid-item--image {
      img {
        width: 100%;
      }

      .rsm-grid-item__title {
        @extend .h4;
        margin-top: 1.5rem;
        width: 100%;
        padding-top: 6px;
        color: $white;
      }
    }
  }

  // w = full width of page
  // p = --rsm-container-padding
  // i = image
  // g = --rsm-grid-item-padding

  // w = 2p + 3i + 4g
  // w/3 = 2p/3 + i + 4g/3
  // i = w/3 - 2p/3 - 4g/3
  // i + 2g = w/3 - 2p/3 - 4g/3 + 6g/3
  // i + 2g = w/3 - 2p/3 + 2g/3
  // i + 2g = (w - 2p + 2g) / 3

  @include media-breakpoint-up(lg) {
    $grid-item-image-width: calc(
      (
          100vw - var(--rsm-container-padding) * 2 -
            var(--rsm-grid-item-padding) * 4
        ) / 3
    );

    $grid-item-inner-width: calc(
      (
          100vw - var(--rsm-container-padding) * 2 +
            var(--rsm-grid-item-padding) * 2
        ) / 3
    );

    grid-template-columns: 1fr #{$grid-item-inner-width} 1fr;

    .rsm-grid-item {
      img {
        width: #{$grid-item-image-width};
      }

      &:nth-child(3n + 1) {
        padding-left: var(--rsm-container-padding);
        border-right: 1px solid $oh-border;
      }

      &:nth-child(3n + 2) {
        border-right: 1px solid $oh-border;
      }

      &:nth-child(3n + 3) {
        padding-right: var(--rsm-container-padding);
        border-right: none;
      }
    }

    .rsm-grid-item:first-child:after,
    .rsm-grid-item:nth-child(2):after {
      @include rsm-grid-line-vertical;
    }
  }

  @include media-breakpoint-between(md, lg) {
    $grid-item-image-width: calc(
      100vw - var(--rsm-container-padding) * 2 - var(--rsm-grid-item-padding) *
        2
    );

    grid-template-columns: 50% 50%;

    .rsm-grid-item {
      img {
        width: #{$grid-item-image-width};
      }

      &:nth-child(odd) {
        padding-left: var(--rsm-container-padding);
        border-right: 1px solid $oh-border;
      }

      &:nth-child(even) {
        padding-right: var(--rsm-container-padding);
        border-right: none;
      }
    }

    .rsm-grid-item:first-child:after {
      @include rsm-grid-line-vertical;
    }
  }

  @include media-breakpoint-down(md) {
    $grid-item-image-width: calc(100vw - var(--rsm-container-padding) * 2);

    grid-template-columns: 100%;

    .rsm-grid-item {
      padding-left: var(--rsm-container-padding);
      padding-right: var(--rsm-container-padding);

      img {
        width: #{$grid-item-image-width};
      }
    }
  }
}
