// Mixins specifically for RSM
@mixin oh-link-glow() {
  color: $oh-teal;
  letter-spacing: 0;
  font-weight: 300;

  &:hover,
  &:focus,
  &.focus {
    text-shadow: 0px 0px 24px $oh-teal;
  }

  &:active,
  &.active {
    color: $oh-light-teal;
  }
}

@mixin line-on-top() {
  position: relative;

  &:before {
    display: block;
    position: absolute;
    top: 0;
    content: '';
    height: 1px;
    background-color: $oh-teal;
    pointer-events: none;
  }
}

@mixin full-md-screen() {
  margin-left: calc((-100vw + 100%) / 2);
  padding-left: var(--rsm-container-padding);
  padding-right: var(--rsm-container-padding);
}

.display-title,
.display-text {
  opacity: 4%;
  letter-spacing: 0px;
  font-weight: 800;
  text-transform: uppercase;
  user-select: none;
  pointer-events: none;
}

.display-title {
  font-size: var(--rsm-display-title-size);
  line-height: var(--rsm-display-line-height);

  @include media-breakpoint-down(xl) {
    font-size: calc(var(--rsm-display-title-size) * 0.85);
  }

  @include media-breakpoint-down(lg) {
    font-size: calc(var(--rsm-display-title-size) * 0.7);
  }

  @include media-breakpoint-down(sm) {
    font-size: var(--rsm-display-title-size);
  }
}

.display-text {
  font-size: var(--rsm-display-text-size);
  line-height: 1em;
}
