// Header Styles
header {
  border-bottom: 1px solid $oh-border;

  .header-top-outer {
    @include rsm-container;

    @include media-breakpoint-down(sm) {
      background-color: $black;
      border-bottom: 1px solid $oh-border;
    }
  }

  .header-lower {
    @include rsm-container;
  }

  .header-top {
    padding: 1.25rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    @include media-breakpoint-down(lg) {
      gap: 1rem;
    }

    @include media-breakpoint-down(sm) {
      padding-bottom: 1rem;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
    }

    &__title {
      text-transform: uppercase;
      font-size: 1.36rem;
      position: relative;
      grid-column: 1 / span 2;

      @include media-breakpoint-down(lg) {
        font-size: 1rem;
      }

      @include media-breakpoint-down(md) {
        font-size: 0.885rem;
      }

      a {
        @include line-on-top();
        display: block;
        margin-bottom: 0;
        text-decoration: none;
        line-height: 1em;
        font-weight: 800;
        padding-top: 0.5rem;
        color: $white;
        transition: all 0.3s;

        &:before {
          width: 34px;
        }

        &:hover {
          color: $oh-teal;
        }
      }
    }

    .header-links {
      display: flex;
      padding-top: 0.5rem;
      margin: 0 0 0 auto;

      .dropdown {
        margin-left: auto;
      }

      .dropdown-menu {
        inset: 0px 0px auto auto !important;
      }

      button {
        box-shadow: none;

        ul {
          padding: 0;
        }

        &:hover {
          background-color: $oh-teal;
          color: $black;
        }
      }

      @include media-breakpoint-down(md) {
        width: 100%;
      }

      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }

    ::placeholder {
      color: $white;
    }

    ::-ms-input-placeholder {
      color: $white;
    }
  }

  .page-main-title {
    position: relative;
    min-height: 95px;
    margin-top: $spacer * 4;

    @include media-breakpoint-down(sm) {
      margin-top: $spacer * 1.5;
    }

    @include media-breakpoint-up(lg) {
      aspect-ratio: 7;
    }

    .page-title {
      line-height: var(--rsm-line-height);
      padding-top: 12px;
      text-transform: uppercase;
      @include line-on-top;
      position: relative;
      z-index: 5;

      &:before {
        width: 41px;

        @include media-breakpoint-down(sm) {
          width: 33px;
        }
      }
    }

    .display-title {
      position: absolute;
      left: -2.5rem;
      top: 1.5rem;
      text-wrap: nowrap;

      @include media-breakpoint-down(lg) {
        left: -0.75rem;
      }
    }
  }
}
